import { Resource as ResourceType } from '../types';

export const mission: ResourceType = {
  name: 'missions',
  label: 'resource.missions.label',
  hideResource: false,
  attributes: [
    {
      key: 'id',
      label: 'resource.missions.field.id.label',
      type: 'text',
    },
    {
      key: 'attachments',
      label: 'resource.missions.field.attachments.label',
      type: 'buttons',
    },
    {
      key: 'begin',
      label: 'resource.missions.field.begin.label',
      type: 'select',
      choices: [
        {
          id: 'asap',
          name: 'resource.missions.field.begin.choices.asap.label',
        },
        {
          id: 'precise',
          name: 'resource.missions.field.begin.choices.precise.label',
        },
        {
          id: 'unknown',
          name: 'resource.missions.field.begin.choices.unknown.label',
        },
      ],
    },
    {
      key: 'begin_at',
      label: 'resource.missions.field.begin_at.label',
      type: 'date',
    },
    {
      key: 'billings',
      label: 'resource.companies.field.billings.label',
      type: 'select',
      choices: [
        {
          id: 'technical_assistance',
          name: 'resource.companies.field.billings.choices.technical_assistance.label',
        },
        {
          id: 'forfeit',
          name: 'resource.companies.field.billings.choices.forfeit.label',
        },
      ],
    },
    {
      key: 'company_confidentiality',
      label: 'resource.missions.field.confidentiality.label',
      type: 'boolean',
    },
    {
      key: 'company_id',
      label: 'resource.missions.field.company_id.label',
      type: 'reference',
      reference: 'companies',
    },
    {
      key: 'practice_culture',
      label: 'resource.companies.field.practice_culture.label',
      type: 'multipleSelect',
      choices: [
        {
          id: 'big_4_and_similar',
          name: 'resource.companies.field.practice_culture.choices.big_4_and_similar',
        },
        {
          id: 'anglo_saxon_firms',
          name: 'resource.companies.field.practice_culture.choices.anglo_saxon_firms',
        },
        {
          id: 'french_international_business_law_firm',
          name: 'resource.companies.field.practice_culture.choices.french_international_business_law_firm',
        },
        {
          id: 'full_service_law_firm_over_50',
          name: 'resource.companies.field.practice_culture.choices.full_service_law_firm_over_50',
        },
        {
          id: 'full_service_law_firm_under_50',
          name: 'resource.companies.field.practice_culture.choices.full_service_law_firm_under_50',
        },
        {
          id: 'specialized_law_firm',
          name: 'resource.companies.field.practice_culture.choices.specialized_law_firm',
        },
        {
          id: 'general_practice_law_firm',
          name: 'resource.companies.field.practice_culture.choices.general_practice_law_firm',
        },
        {
          id: 'council_of_state_law_firm',
          name: 'resource.companies.field.practice_culture.choices.council_of_state_law_firm',
        },
        {
          id: 'individual_law_practitioner',
          name: 'resource.companies.field.practice_culture.choices.individual_law_practitioner',
        },
        {
          id: 'legal_department_of_a_french_company',
          name: 'resource.companies.field.practice_culture.choices.legal_department_of_a_french_company',
        },
        {
          id: 'legal_department_of_an_international_company',
          name: 'resource.companies.field.practice_culture.choices.legal_department_of_an_international_company',
        },
      ],
    },
    {
      key: 'company_level',
      label: 'resource.missions.field.company_level.label',
      type: 'range',
    },
    {
      key: 'context',
      label: 'resource.missions.field.context.label',
      type: 'text',
    },
    {
      key: 'context_description',
      label: 'resource.missions.field.context_description.label',
      type: 'wysiwyg',
    },
    {
      key: 'name',
      label: 'resource.missions.field.name.label',
      type: 'text',
    },
    {
      key: 'company_name',
      label: 'resource.missions.field.company_id.label',
      type: 'text',
    },
    {
      key: 'created_at',
      label: 'resource.missions.field.created_at.label',
      type: 'date',
    },
    {
      key: 'hourly_rate',
      label: 'resource.missions.field.hourly_rate.label',
      type: 'number',
    },
    {
      key: 'end',
      label: 'resource.missions.field.end.label',
      type: 'select',
      choices: [
        {
          id: 'approximative',
          name: 'resource.missions.field.end.choices.approximative.label',
        },
        {
          id: 'precise',
          name: 'resource.missions.field.end.choices.precise.label',
        },
        {
          id: 'unknown',
          name: 'resource.missions.field.end.choices.unknown.label',
        },
      ],
    },
    {
      key: 'end_at',
      label: 'resource.missions.field.end_at.label',
      type: 'date',
    },
    {
      key: 'end_months',
      label: 'resource.missions.field.end_months.label',
      type: 'number',
    },
    {
      key: 'location',
      type: 'object',
      shape: [
        {
          key: 'address',
          type: 'text',
          label: 'resource.missions.field.location.address.label',
        },
      ],
    },
    {
      key: 'modalities',
      label: 'resource.companies.field.modalities.label',
      type: 'select',
      choices: [
        {
          id: 'remote',
          name: 'resource.companies.field.modalities.choices.remote.label',
        },
        {
          id: 'remote_can_move',
          name: 'resource.companies.field.modalities.choices.remote_can_move.label',
        },
        {
          id: 'on_site',
          name: 'resource.companies.field.modalities.choices.on_site.label',
        },
      ],
    },
    {
      key: 'name',
      label: 'resource.missions.field.name.label',
      type: 'text',
    },
    {
      key: 'nda',
      label: 'resource.missions.field.nda.label',
      type: 'boolean',
    },
    {
      key: 'nda_attachment',
      label: 'resource.missions.field.nda_attachments.label',
      type: 'buttons',
    },
    {
      key: 'owner_id',
      label: 'resource.missions.field.owner_id.label',
      type: 'reference',
      reference: 'users',
    },
    {
      key: 'sectors',
      label: 'resource.companies.field.sectors.label',
      type: 'multipleSelect',
      choices: [
        {
          id: 'leaders_and_shareholders',
          name: 'resource.companies.field.sectors.choices.leaders_and_shareholders.label',
        },
        {
          id: 'investment_funds',
          name: 'resource.companies.field.sectors.choices.investment_funds.label',
        },
        {
          id: 'listed_companies',
          name: 'resource.companies.field.sectors.choices.listed_companies.label',
        },
        {
          id: 'startups_and_innovation',
          name: 'resource.companies.field.sectors.choices.startups_and_innovation.label',
        },
        {
          id: 'digital_and_e_commerce',
          name: 'resource.companies.field.sectors.choices.digital_and_e_commerce.label',
        },
        {
          id: 'media_and_communication',
          name: 'resource.companies.field.sectors.choices.media_and_communication.label',
        },
        {
          id: 'franchise_distribution_commerce',
          name: 'resource.companies.field.sectors.choices.franchise_distribution_commerce.label',
        },
        {
          id: 'entertainment',
          name: 'resource.companies.field.sectors.choices.entertainment.label',
        },
        {
          id: 'hospitality_and_leisure_restaurant',
          name: 'resource.companies.field.sectors.choices.hospitality_and_leisure_restaurant.label',
        },
        {
          id: 'banking_insurance_mutual',
          name: 'resource.companies.field.sectors.choices.banking_insurance_mutual.label',
        },
        {
          id: 'textile_and_luxury',
          name: 'resource.companies.field.sectors.choices.textile_and_luxury.label',
        },
        {
          id: 'real_estate_and_development',
          name: 'resource.companies.field.sectors.choices.real_estate_and_development.label',
        },
        {
          id: 'industrial',
          name: 'resource.companies.field.sectors.choices.industrial.label',
        },
        {
          id: 'institutional',
          name: 'resource.companies.field.sectors.choices.institutional.label',
        },
        {
          id: 'non_profit_organizations_and_social_economy',
          name: 'resource.companies.field.sectors.choices.non_profit_organizations_and_social_economy.label',
        },
        {
          id: 'energy_and_environment',
          name: 'resource.companies.field.sectors.choices.energy_and_environment.label',
        },
        {
          id: 'liberal_professions',
          name: 'resource.companies.field.sectors.choices.liberal_professions.label',
        },
        {
          id: 'artisans_and_trades_people',
          name: 'resource.companies.field.sectors.choices.artisans_and_trades_people.label',
        },
        {
          id: 'institutional_and_public_figures',
          name: 'resource.companies.field.sectors.choices.institutional_and_public_figures.label',
        },
        {
          id: 'individuals_and_families',
          name: 'resource.companies.field.sectors.choices.individuals_and_families.label',
        },
        {
          id: 'multisectoral_or_no_sectorial_expertise',
          name: 'resource.companies.field.sectors.choices.multisectoral_or_no_sectorial_expertise.label',
        },
        {
          id: 'health_pharma_and_biotechnology',
          name: 'resource.companies.field.sectors.choices.health_pharma_and_biotechnology.label',
        },
      ],
    },
    {
      key: 'skills_name',
      label: 'resource.missions.field.skills_name.label',
      type: 'array',
      itemSource: 'name',
    },
    {
      key: 'status',
      label: 'resource.missions.field.status.label',
      type: 'select',
      choices: [
        {
          id: 'draft',
          name: 'resource.missions.field.status.choices.draft.label',
        },
        {
          id: 'published',
          name: 'resource.missions.field.status.choices.published.label',
        },
        {
          id: 'stand_by',
          name: 'resource.missions.field.status.choices.stand_by.label',
        },
        {
          id: 'review',
          name: 'resource.missions.field.status.choices.review.label',
        },
        {
          id: 'wip',
          name: 'resource.missions.field.status.choices.wip.label',
        },
        {
          id: 'archived',
          name: 'resource.missions.field.status.choices.archived.label',
        },
        {
          id: 'finished',
          name: 'resource.missions.field.status.choices.finished.label',
        },
      ],
    },
    {
      key: 'sub_categories_id',
      label: 'resource.companies.field.sub_categories_name.label',
      type: 'referenceArray',
      reference: 'sub_categories',
    },
    {
      key: 'updated_at',
      label: 'resource.missions.field.updated_at.label',
      type: 'date',
    },
    {
      key: 'validated',
      label: 'resource.missions.field.validated.label',
      type: 'boolean',
    },
    {
      key: 'validated_and_not_stand_by',
      label: 'resource.missions.field.validated.label',
      type: 'boolean',
    },
    {
      key: 'validated_at',
      label: 'resource.missions.field.validated_at.label',
      type: 'date',
    },
    {
      key: 'vip',
      label: 'resource.missions.field.vip.label',
      type: 'boolean',
    },
    {
      key: 'company_vip_access',
      label: 'resource.missions.field.vip.label',
      type: 'boolean',
    },
    {
      key: 'work_days',
      label: 'resource.missions.field.work_days.label',
      type: 'number',
    },
    {
      key: 'applicants_number',
      label: 'resource.missions.field.applicants_number.label',
      type: 'number',
    },
    {
      key: 'archived_kind',
      label: 'resource.missions.field.archived_kind.label',
      type: 'select',
      choices: [
        {
          id: 'won',
          name: 'resource.missions.field.archived_kind.choices.won.label',
        },
        {
          id: 'cancelled_by_client',
          name: 'resource.missions.field.archived_kind.choices.cancelled_by_client.label',
        },
        {
          id: 'cancelled_by_owner',
          name: 'resource.missions.field.archived_kind.choices.cancelled_by_owner.label',
        },
        {
          id: 'staffed_by_competitor',
          name: 'resource.missions.field.archived_kind.choices.staffed_by_competitor.label',
        },
        {
          id: 'no_news_from_client',
          name: 'resource.missions.field.archived_kind.choices.no_news_from_client.label',
        },
      ],
    },
    {
      key: 'archived_text',
      label: 'resource.missions.field.archived_text.label',
      type: 'text',
    },
    {
      key: 'work_hours',
      label: 'resource.missions.field.work_hours.label',
      type: 'number',
    },
    {
      key: 'kind',
      label: 'resource.missions.field.kind.label',
      type: 'text',
    },
    {
      key: 'recruitment',
      label: 'resource.missions.field.recruitment.label',
      type: 'boolean',
    },
    {
      key: 'request_for_quotation',
      label: 'resource.missions.field.request_for_quotation.label',
      type: 'boolean',
    },
  ],
  pages: {
    list: {
      hasEditButton: true,
      search: {
        enabled: true,
        label: 'filter.search.label',
      },
      filters: [
        {
          source: 'status',
          type: 'multipleSelect',
          optionsFilter: {
            className: 'multipleSelectFilter',
          },
        },
      ],
      fields: [
        {
          source: 'owner_id',
          // Reference fields need children to display
          label: 'resource.missions.field.company_id.label',
          child: {
            source: 'users.company_name',
          },
        },
        {
          source: 'context',
        },
        {
          source: 'name',
          sortable: false,
        },
        {
          source: 'kind',
        },
        {
          source: 'status',
        },
        {
          source: 'hourly_rate',
        },
        {
          source: 'validated_at',
        },
        {
          source: 'created_at',
        },
      ],
      bulkActionButtons: [
        {
          name: 'transfer',
          type: 'modal',
          label: 'resource.missions.bulk_action_buttons.transfer.label',
          extraUrl: '/transfer',
          confirmation: {
            title: 'modal.select_company.transfer_project',
          },
          modal: {
            inputs: {
              company_id: {
                source: 'companies',
                filter: { user_kind: 'client_or_mixed' },
                label: 'modal.select_company.label',
                child: {
                  type: 'autocomplete',
                  optionText: 'name',
                },
              },
            },
          },
        },
        {
          name: 'accept',
          type: 'update',
          label: 'resource.missions.bulk_action_buttons.accept.label',
          values: {
            validated: true,
          },
        },
        {
          name: 'stand_by',
          type: 'update',
          label: 'resource.missions.bulk_action_buttons.stand_by.label',
          values: {
            stand_by: true,
          },
        },
        {
          name: 'archive',
          type: 'update',
          label: 'resource.missions.bulk_action_buttons.archive.label',
          values: {
            archived: true,
          },
        },
      ],
    },
    edit: {
      singleRedirect: {
        page: 'edit',
        basePath: '/missions',
        recordId: 'id',
      },
      actions: [
        {
          name: 'accept',
          type: 'update',
          label: 'resource.missions.bulk_action_buttons.accept.label',
          values: { validated: true },
          extraUrl: '/',
          disabled: [
            { property: 'validated_and_not_stand_by', op: 'eq', value: true },
          ],
        },
        {
          name: 'stand_by',
          type: 'update',
          label: 'resource.missions.bulk_action_buttons.stand_by.label',
          values: {
            stand_by: true,
          },
          extraUrl: '/',
          disabled: [{ property: 'status', op: 'neq', value: 'published' }],
        },
        {
          name: 'archive',
          type: 'update',
          label: 'resource.missions.bulk_action_buttons.archive.label',
          values: { archived: true },
          extraUrl: '/',
          disabled: [{ property: 'status', op: 'eq', value: 'archived' }],
        },
      ],
      left: {
        tabs: [
          {
            key: 'information',
            title: 'resource.missions.tab.information.title',
            sections: [
              {
                key: 'profile',
                title: 'resource.missions.section.profile.title',
                layout: [
                  ['name'],
                  ['kind'],
                  ['skills_name'],
                  ['practice_culture'],
                  ['company_level'],
                  ['sub_categories_id'],
                  ['sectors'],
                ],
                inputs: {
                  name: {
                    disabled: true,
                    validators: [{ key: 'required' }],
                  },
                  kind: {
                    disabled: true,
                    validators: [{ key: 'required' }],
                  },
                  practice_culture: {
                    disabled: true,
                    validators: [{ key: 'required' }],
                  },
                  company_level: {
                    validators: [{ key: 'required' }],
                    options: {
                      min: 0,
                      max: 10,
                      step: 1,
                    },
                  },
                  sectors: {
                    disabled: true,
                    validators: [{ key: 'required' }],
                  },
                  sub_categories_id: {
                    child: {
                      type: 'autocompleteArray',
                      optionText: 'sub_categories_name_phrase',
                      options: {
                        perPage: 184,
                        allowEmpty: false,
                      },
                      extensions: [
                        {
                          key: 'sort',
                          params: {
                            type: 'alphabetic',
                            mode: 'asc',
                          },
                        },
                        {
                          key: 'catLengthValidation',
                          params: {
                            max: 2,
                            message:
                              'resource.validators.category_length.message',
                          },
                        },
                      ],
                    },
                  },
                },
                fields: {
                  skills_name: {
                    addLabel: true,
                    link: false,
                  },
                },
              },
              {
                key: 'modality',
                title: 'resource.missions.section.modality.title',
                layout: [
                  ['begin', 'begin_at'],
                  ['end', 'end_at', 'end_months'],
                  ['work_days', 'work_hours'],
                  ['location.address', 'modalities'],
                  ['billings'],
                  ['applicants_number'],
                  ['recruitment'],
                  ['hourly_rate'],
                  ['request_for_quotation'],
                ],
                inputs: {
                  begin: {
                    width: 6,
                    validators: [{ key: 'required' }],
                  },
                  begin_at: {
                    width: 6,
                    validators: [{ key: 'required' }],
                    rules: {
                      hide: [
                        {
                          property: 'begin',
                          op: 'neq',
                          value: 'precise',
                        },
                      ],
                    },
                  },
                  end: {
                    width: 4,
                    validators: [{ key: 'required' }],
                  },
                  end_at: {
                    width: 4,
                    validators: [{ key: 'required' }],
                    rules: {
                      hide: [
                        {
                          property: 'end',
                          op: 'neq',
                          value: 'precise',
                        },
                      ],
                    },
                  },
                  end_months: {
                    width: 4,
                    validators: [{ key: 'required' }],
                    rules: {
                      hide: [
                        {
                          property: 'end',
                          op: 'neq',
                          value: 'approximative',
                        },
                      ],
                    },
                  },
                  work_days: {
                    width: 6,
                    step: 0.5,
                    validators: [{ key: 'required' }],
                    rules: {
                      hide: [
                        {
                          property: 'billings',
                          op: 'eq',
                          value: 'forfeit',
                        },
                      ],
                    },
                  },
                  work_hours: {
                    width: 6,
                    validators: [{ key: 'required' }],
                    min: 1,
                    max: 1000,
                    step: 1,
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'eq',
                          value: 'expert',
                        },
                      ],
                    },
                  },
                  'location.address': {
                    width: 6,
                    disabled: true,
                    validators: [{ key: 'required' }],
                  },
                  modalities: {
                    width: 6,
                    validators: [{ key: 'required' }],
                  },
                  billings: {
                    validators: [{ key: 'required' }],
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'eq',
                          value: 'expert',
                        },
                      ],
                    },
                  },
                  applicants_number: {
                    validators: [
                      { key: 'required' },
                      { key: 'minLength', value: 1 },
                    ],
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'eq',
                          value: 'expert',
                        },
                      ],
                    },
                  },
                  recruitment: {
                    disabled: true,
                    validators: [{ key: 'required' }],
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'eq',
                          value: 'expert',
                        },
                      ],
                    },
                  },
                  hourly_rate: {
                    validators: [{ key: 'required' }],
                    min: 70,
                    max: 2500,
                    step: 5,
                  },
                  request_for_quotation: {
                    disabled: true,
                    validators: [{ key: 'required' }],
                    rules: {
                      hide: [
                        {
                          property: 'kind',
                          op: 'eq',
                          value: 'teams',
                        },
                      ],
                    },
                  },
                },
              },
              {
                key: 'projet',
                title: 'resource.missions.section.projet.title',
                layout: [['context'], ['context_description'], ['attachments']],
                inputs: {
                  context: {
                    validators: [{ key: 'required' }],
                  },
                  context_description: {
                    validators: [
                      { key: 'required' },
                      {
                        key: 'maxLengthHTML',
                        value: 10000,
                      },
                    ],
                  },
                },
                fields: {
                  attachments: {
                    addLabel: true,
                    extensions: [
                      {
                        key: 'attachmentsFiles',
                      },
                    ],
                  },
                },
              },
              {
                key: 'confidentiality',
                title: 'resource.missions.section.confidentiality.title',
                layout: [
                  ['nda', 'nda_attachment'],
                  ['vip'],
                  ['company_confidentiality'],
                ],
                inputs: {
                  nda: {
                    width: 4,
                    disabled: true,
                    validators: [{ key: 'required' }],
                  },
                  vip: {
                    disabled: true,
                    validators: [{ key: 'required' }],
                    rules: {
                      hide: [
                        {
                          property: 'company_vip_access',
                          op: 'eq',
                          value: false,
                        },
                      ],
                    },
                  },
                  company_confidentiality: {
                    validators: [{ key: 'required' }],
                  },
                },
                fields: {
                  nda_attachment: {
                    addLabel: true,
                    extensions: [
                      {
                        key: 'attachmentsFiles',
                      },
                    ],
                    rules: {
                      hide: [{ property: 'nda', op: 'eq', value: false }],
                    },
                  },
                },
              },
              {
                key: 'archived',
                title: 'resource.missions.section.archived.title',
                layout: [['archived_kind'], ['archived_text']],
                inputs: {
                  archived_kind: {},
                  archived_text: {
                    disabled: true,
                  },
                },
                rules: {
                  hide: [
                    {
                      property: 'status',
                      op: 'neq',
                      value: 'archived',
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
      right: [
        {
          sections: [
            {
              key: 'details',
              title: 'resource.missions.section.details.title',
              layout: [
                ['id', 'status'],
                ['created_at', 'updated_at'],
                ['validated_at'],
                ['owner_id'],
                ['company_name'],
              ],
              inputs: {
                id: {
                  width: 6,
                  disabled: true,
                },
                status: {
                  width: 6,
                  disabled: true,
                },
                created_at: {
                  disabled: true,
                  width: 6,
                },
                updated_at: {
                  disabled: true,
                  width: 6,
                },
                validated_at: {
                  disabled: true,
                },
              },
              fields: {
                owner_id: {
                  addLabel: true,
                  child: {
                    type: 'text',
                    source: 'users.full_name',
                  },
                },
                company_name: {
                  addLabel: true,
                  link: false,
                },
              },
            },
          ],
        },
        {
          sections: [
            {
              key: 'history',
              title: 'resource.missions.section.history.title',
              layout: [],
              inputs: {},
              history: true,
            },
          ],
        },
      ],
    },
  },
};
